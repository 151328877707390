import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const PropertyMaintenance = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Comprehensive Property Maintenance Services</Heading>
        <Text>
          At West Hollywood Property Managers, our commitment to maintaining the
          integrity and appeal of your property is paramount. Our dedicated team
          of maintenance experts is equipped to handle every aspect of property
          upkeep, ensuring your investment retains its value and tenants enjoy a
          top-tier living experience. From the verdant landscape that greets
          your guests to the pristine operation of every facility, we ensure
          that your property epitomizes excellence.
        </Text>
        <Text>
          We understand the pivotal role a well-maintained property plays in
          ensuring tenant satisfaction and enhancing property value. We take
          pride in offering a comprehensive suite of maintenance services that
          cater to every aspect of your property, whether it's related to
          aesthetics or functionality. Our dedicated team of skilled
          professionals is committed to delivering top-notch service, ensuring
          that your property not only looks its best but also functions
          seamlessly. From routine inspections to emergency repairs, we've got
          you covered. Aesthetic considerations are crucial in creating a
          welcoming and attractive environment for your tenants. Our maintenance
          services include landscaping, painting, and other cosmetic
          enhancements to elevate the visual appeal of your property. We believe
          that a visually appealing property contributes significantly to tenant
          satisfaction and loyalty. On the functional side, we address all
          aspects of property maintenance, from plumbing and electrical issues
          to HVAC system checks. Our proactive approach to maintenance helps
          identify potential problems before they escalate, saving you both time
          and money in the long run.
        </Text>

        <UnorderedList spacing={3}>
          <ListItem>
            <b>Landscaping Excellence:</b> Our landscaping services go beyond
            mere lawn care. We craft and maintain a welcoming outdoor space that
            resonates with the vibrant West Hollywood spirit, ensuring that your
            property always makes a memorable first impression.
          </ListItem>
          <ListItem>
            <b>Responsive Repairs:</b> From minor fixes to major overhauls, our
            responsive maintenance team is on standby to address issues swiftly
            and efficiently, minimizing inconvenience to your tenants and
            preventing minor issues from escalating.
          </ListItem>
          <ListItem>
            <b>Preventative Inspections:</b> We conduct regular, detailed
            inspections to foresee and address potential problems. This
            proactive approach to property care keeps unexpected costs down and
            extends the life of your property’s critical systems.
          </ListItem>
          <ListItem>
            <b>Strategic Vendor Partnerships:</b> We leverage our long-standing
            relationships with trusted vendors to bring you the best possible
            service at the most competitive rates, ensuring that every aspect of
            your property is in expert hands.
          </ListItem>
          <ListItem>
            <b>Energy Efficiency Upgrades:</b> Our commitment to sustainability
            extends to energy-efficient upgrades for your property. From LED
            lighting installations to smart thermostat systems, we help reduce
            your property's carbon footprint while saving you money on utility
            bills.
          </ListItem>
          <ListItem>
            <b>24/7 Emergency Support:</b> Emergencies can happen at any time,
            which is why our dedicated support team is available around the
            clock to address urgent maintenance issues. Whether it's a burst
            pipe or a power outage, you can count on us to respond promptly and
            effectively.
          </ListItem>
          <ListItem>
            <b>Community Engagement Initiatives:</b> We believe in fostering a
            sense of community within your property. Through various engagement
            initiatives such as resident events and feedback sessions, we strive
            to create a positive living experience for all tenants while
            promoting a strong sense of belonging.
          </ListItem>
        </UnorderedList>

        <Text>
          Let West Hollywood Property Managers elevate your property maintenance
          to new heights. Our comprehensive services are tailored to meet the
          unique needs of your property, allowing you to enjoy the benefits of
          property ownership without the operational complexities. At West
          Hollywood Property Managers, we understand that every property is
          different, and we take pride in offering personalized solutions that
          exceed your expectations. From routine maintenance to emergency
          repairs, our dedicated team is committed to keeping your property in
          pristine condition. With our expertise and attention to detail, you
          can trust us to handle all aspects of property maintenance with
          professionalism and care. Whether it's enhancing curb appeal through
          landscaping excellence or ensuring timely responsive repairs, we are
          here to support you every step of the way. Connect with us to discuss
          how we can keep your property functioning flawlessly and looking its
          best. Let us partner with you to streamline your property management
          responsibilities, allowing you to focus on other aspects of your
          business with confidence.
        </Text>
      </Stack>
    </Box>
  );
};
