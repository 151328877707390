import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const LegalServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Comprehensive Legal Services for Property Management
        </Heading>
        <Text>
          At West Hollywood Property Management, we offer an integrated suite of
          legal services tailored to the complex and ever-evolving field of
          property law. Our seasoned legal experts provide robust support to
          ensure your real estate operations adhere to the highest legal
          standards.
        </Text>
        <Text>
          With an intimate understanding of both local ordinances and state
          regulations, our team acts as your personal navigator through the
          legal intricacies of property management. Whether it's staying ahead
          of legislative changes or handling sensitive disputes, we have the
          expertise to mitigate risks and protect your assets.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Regulatory Compliance:</b> Our vigilant oversight ensures that
            your property remains in strict compliance with all housing laws,
            building codes, and safety regulations, thereby safeguarding you
            from potential liabilities. We conduct regular audits and
            inspections to identify and address any compliance issues
            proactively.
          </ListItem>
          <ListItem>
            <b>Dedicated Legal Counsel:</b> Receive expert legal advice on all
            matters pertaining to your property. Our counsel helps you make
            strategic decisions that align with both your financial interests
            and legal responsibilities. From lease agreements to property
            disputes, our legal team is dedicated to protecting your rights and
            interests every step of the way.
          </ListItem>
          <ListItem>
            <b>Effective Dispute Resolution:</b> We employ tactful mediation and
            negotiation strategies to resolve disputes between landlords and
            tenants, ensuring peaceful and cost-effective solutions. Our goal is
            to foster open communication and find mutually beneficial
            resolutions that preserve the landlord-tenant relationship while
            upholding legal obligations.
          </ListItem>
          <ListItem>
            <b>Streamlined Eviction Services:</b> When evictions become
            necessary, rely on our expertise to conduct the process ethically
            and legally, minimizing stress and upholding the dignity of all
            parties involved. Our experienced team handles all aspects of the
            eviction process, from serving notices to representing you in court,
            with professionalism and respect.
          </ListItem>
          <ListItem>
            <b>Tenant Education and Support:</b> We believe in proactive tenant
            education to prevent misunderstandings and disputes. Through clear
            communication and resources, we empower tenants to understand their
            rights and responsibilities, fostering a positive and harmonious
            living environment within your property.
          </ListItem>
          <ListItem>
            <b>Emergency Response Planning:</b> Our comprehensive emergency
            response plans ensure that your property is prepared for any
            unforeseen circumstances, from natural disasters to property
            emergencies. With clear protocols and trained staff, we prioritize
            the safety and well-being of your tenants while minimizing
            disruption to your property operations.
          </ListItem>
        </UnorderedList>

        <Text>
          Trust in West Hollywood Property Management to deliver legal services
          that not only keep you compliant but also empower you with the
          confidence to grow your property portfolio. Our dedicated legal team
          is committed to providing comprehensive legal support tailored to your
          specific needs and objectives. With our expertise in property law and
          regulations, we ensure that your property remains in full compliance
          with local, state, and federal laws. From lease agreements to property
          disputes, we offer strategic guidance and representation to safeguard
          your interests and minimize legal risks. At West Hollywood Property
          Management, we understand that legal matters can be complex and
          challenging to navigate. That's why we prioritize clear communication
          and proactive problem-solving, keeping you informed and involved every
          step of the way. Whether you're a seasoned property investor or just
          starting, our legal services are designed to support your growth and
          success in property management. Connect with us to learn more about
          how our legal expertise can be your greatest asset in property
          management.
        </Text>
      </Stack>
    </Box>
  );
};
