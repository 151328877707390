export const posts = [
    {
        "id": "1",
        "title": "Maximizing Property Value Through Effective Management",
        "excerpt": "Discover how Los Angeles Property Management Pros can elevate the value of your property with our comprehensive management services.",
        "slug": "maximizing-property-value-through-effective-management",
        "tags": ["Property Management", "Value", "Investment"],
        "metaDescription": "Learn how to maximize your property value with professional management services from Los Angeles Property Management Pros.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "2",
        "title": "Tenant Screening: Your First Line of Defense",
        "excerpt": "Tenant screening is crucial for a secure and profitable investment. Find out why and how we do it right at Los Angeles Property Management Pros.",
        "slug": "tenant-screening-your-first-line-of-defense",
        "tags": ["Tenant Screening", "Safety", "Investment"],
        "metaDescription": "Understand the importance of effective tenant screening with Los Angeles Property Management Pros.",
        lastModified: new Date('2023-12-15').toISOString()
    },
    {
        "id": "3",
        "title": "Legal Services: Navigating The Complexities of Property Law",
        "excerpt": "From rent control to tenant-landlord disputes, property law can be tricky. Find out how we can guide you through the legal maze.",
        "slug": "legal-services-navigating-the-complexities-of-property-law",
        "tags": ["Legal Services", "Property Law", "Expertise"],
        "metaDescription": "Los Angeles Property Management Pros offer expert legal services for property owners, ensuring peace of mind and compliance.",
        lastModified: new Date('2023-12-20').toISOString()
    },
    {
        "id": "4",
        "title": "Financial Reporting: Keeping You in the Loop",
        "excerpt": "Stay informed and on top of your finances with our detailed financial reporting services for property owners.",
        "slug": "financial-reporting-keeping-you-in-the-loop",
        "tags": ["Financial Reporting", "Transparency", "Accountability"],
        "metaDescription": "Los Angeles Property Management Pros provide comprehensive financial reporting to ensure property owners are always informed.",
        lastModified: new Date('2024-1-04').toISOString()
    }
]
