import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const LeasingServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Comprehensive Leasing Services for West Hollywood Real Estate
        </Heading>
        <Text>
          At West Hollywood Property Managers, we understand that the leasing
          process is the cornerstone of successful property management. Our
          leasing services are designed to streamline the rental cycle, from
          property listing to tenant move-out, ensuring that your real estate
          investment yields maximum returns with minimal stress.
        </Text>
        <Text>
          Our team brings local market expertise, legal acumen, and dedicated
          customer service to every stage of the leasing process. With a focus
          on securing reliable tenants and maintaining high occupancy rates, our
          leasing strategies are an integral part of our clients' success in the
          vibrant West Hollywood market.
        </Text>
        <Heading as={"h3"} fontSize="2xl">
          Our Suite of Professional Leasing Services
        </Heading>
        <Text>
          We provide an array of leasing services that cover every aspect of
          tenant acquisition and retention:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Targeted Property Marketing:</b> We harness the power of both
            digital and traditional platforms, listing your property on premier
            real estate websites to ensure it reaches a wide but targeted
            audience. Our marketing strategies are tailored to highlight the
            unique features and benefits of your property, maximizing visibility
            and attracting qualified tenants.
          </ListItem>
          <ListItem>
            <b>Expert Lease Negotiation:</b> Our skilled negotiators work on
            your behalf to secure favorable lease terms, balancing competitive
            pricing with the protection of your interests. We conduct thorough
            market research and leverage our industry expertise to negotiate
            terms that optimize your rental income while mitigating risks and
            liabilities.
          </ListItem>
          <ListItem>
            <b>Meticulous Contract Management:</b> We meticulously manage all
            leasing documentation, ensuring every contract meets current legal
            requirements and protects your rights as a property owner. From
            lease agreements to addendums, our attention to detail ensures that
            your interests are fully represented and safeguarded throughout the
            leasing process.
          </ListItem>
          <ListItem>
            <b>Streamlined Move-In/Move-Out Procedures:</b> Our efficient
            move-in and move-out processes include thorough property inspections
            and detailed documentation, safeguarding your property’s condition
            and value. We conduct comprehensive move-in inspections to record
            the property's condition and address any maintenance issues before
            the tenant assumes occupancy. Similarly, our move-out procedures
            ensure that the property is returned in the same condition,
            minimizing disputes and ensuring a smooth transition between
            tenants.
          </ListItem>
          <ListItem>
            <b>Responsive Tenant Communication:</b> We prioritize open and
            transparent communication with tenants, addressing their inquiries
            and concerns promptly and professionally. By fostering positive
            tenant relationships, we promote tenant satisfaction and retention,
            reducing turnover and vacancy rates.
          </ListItem>
          <ListItem>
            <b>Continuous Property Maintenance:</b> Our proactive approach to
            property maintenance ensures that your investment remains in optimal
            condition. From routine inspections to timely repairs, we address
            maintenance issues promptly, preserving the value of your property
            and enhancing tenant satisfaction.
          </ListItem>
        </UnorderedList>

        <Text>
          Our leasing services extend beyond simply filling vacancies. We focus
          on cultivating lasting relationships with tenants, which begins with a
          seamless leasing experience. From the initial property showing to the
          final move-out walkthrough, West Hollywood Property Managers ensures
          every interaction is professional, responsive, and conducive to
          long-term tenant engagement.
        </Text>
        <Text>
          Partner with us to benefit from our full-service leasing solutions.
          Our expertise in the West Hollywood property market allows us to
          perform beyond expectations, ensuring your properties are not only
          leased efficiently but also managed with the utmost professionalism
          and attention to detail.
        </Text>
        <Text>
          Get in touch with West Hollywood Property Managers to learn more about
          how our bespoke leasing services can elevate your property’s potential
          and contribute to your success as a property owner in West Hollywood.
        </Text>
      </Stack>
    </Box>
  );
};
