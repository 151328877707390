import React from "react";
import {Box, Heading, ListItem, Stack, UnorderedList, Text} from "@chakra-ui/react";

export const LeaseManagement = ()=>{
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'} fontSize="lg">Expert Lease Management Services</Heading>
                <Text>
                    Navigating the complexities of lease agreements is an art that West Hollywood Property Managers has perfected. Our lease management services are tailor-made to streamline your role as a property owner, ensuring that every aspect of lease handling is conducted with professionalism and acute attention to detail. We turn the intricate process of lease management into a seamless operation that safeguards your investment and maximizes your property's potential.
                </Text>
                <Text>
                    With a keen understanding of the West Hollywood real estate market and the intricacies of property law, we provide a robust lease management framework that serves as the backbone for the successful and profitable operation of your rental property.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Comprehensive Drafting:</b> Our leases are more than documents; they are peace of mind written on paper. Crafted with expertise, they cover all bases to protect your interests and ensure clear understanding of the terms for tenants.
                    </ListItem>
                    <ListItem>
                        <b>Enforcement and Compliance:</b> We monitor compliance diligently and enforce lease terms fairly and firmly. Any violations are addressed promptly to ensure that small issues don’t escalate into bigger problems.
                    </ListItem>
                    <ListItem>
                        <b>Proactive Renewals:</b> Our strategic approach to renewals anticipates tenant turnover, allowing us to act timely to encourage continuous occupancy and maintain your revenue stream.
                    </ListItem>
                    <ListItem>
                        <b>Handled Terminations:</b> Whether a lease comes to a natural end or unexpected circumstances necessitate termination, we manage the process ethically and in accordance with legal standards to protect both you and your tenants.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Embrace the full spectrum of lease management benefits with West Hollywood Property Managers. Our dedication to detail and our commitment to client satisfaction make us the premier choice for property owners seeking comprehensive lease management solutions. Let's discuss how our expert lease management can enhance the value and efficiency of your property management experience.
                </Text>
            </Stack>
        </Box>
    )
}