import React from "react";
import {Box, Heading, ListItem, Stack, UnorderedList, Text} from "@chakra-ui/react";

export const PropertyManagement = ()=>{
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h1'} fontSize="xl">Leading Property Management Services in West Hollywood</Heading>
                <Text>
                    Introducing West Hollywood Property Management, the premier destination for property owners seeking meticulous oversight and management of their real estate investments. In the vibrant heart of West Hollywood, CA, our property management services are synonymous with reliability, efficiency, and unparalleled expertise.
                </Text>
                <Text>
                    With years of experience in the West Hollywood market, our team of skilled professionals ensures your property is managed to the highest standard. By combining our deep understanding of the local real estate landscape with innovative property management technology, we guarantee a comprehensive service package tailored to the unique needs of your investment.
                </Text>
                <Heading as={'h2'} fontSize="lg">Unrivaled Property Management Solutions</Heading>
                <Text>
                    Our full spectrum of property management services is crafted to alleviate the typical pressures associated with property ownership. Through our dedicated approach, we strive to enhance your property's value and secure the highest possible returns, all while maintaining a positive living experience for your tenants.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>In-depth Tenant Screening:</b> Our tenant screening process is thorough and detail-oriented, ensuring that each candidate is meticulously evaluated. This includes a full credit report, criminal background check, eviction history review, and verification of employment and rental history. By securing qualified and dependable tenants, we minimize turnover rates and maintain a consistent revenue stream for your property.
                    </ListItem>
                    <ListItem>
                        <b>Proactive Property Maintenance:</b> We take a proactive approach to property maintenance, emphasizing both preventative care and swift response to repair needs. Our network of trusted contractors and in-house maintenance staff are on call to address issues promptly, ensuring that your property remains in pristine condition, which is vital to retaining its value over time.
                    </ListItem>
                    <ListItem>
                        <b>Efficient Rent Collection:</b> Rent collection is streamlined through our secure online portal, offering convenience to tenants and ensuring consistent cash flow for owners. In the rare event of late payments, we handle the situation with professionalism and tact, enforcing policies fairly while maintaining positive tenant relations.
                    </ListItem>
                    <ListItem>
                        <b>Legal Compliance and Documentation:</b> Staying abreast of the ever-changing property laws and regulations can be daunting. We offer peace of mind by managing all legal aspects related to your property, including lease agreements, eviction procedures, and adherence to fair housing laws. Our comprehensive documentation ensures that you are legally secure and compliant with all local, state, and federal regulations.
                    </ListItem>
                    <ListItem>
                        <b>Detailed Financial Reporting:</b> Knowledge is power, especially when it comes to investment properties. Our financial reporting tools provide a clear and concise picture of your property's financial status, including income statements, balance sheets, and detailed expense reports. We take transparency seriously, ensuring you have 24/7 access to your property's financial data.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Beyond these key services, West Hollywood Property Management offers tailored solutions including market analysis, strategic advertising, lease renewal negotiations, and comprehensive eviction support. Our goal is to provide you with a turnkey property management solution that allows you to enjoy the benefits of property ownership without the associated operational complexities.
                </Text>
                <Text>
                    Our commitment to excellence is evident in every interaction. From the initial property assessment to ongoing management and reporting, we are dedicated to achieving optimal performance for your real estate investment. By choosing West Hollywood Property Management, you are partnering with a team that values integrity, transparency, and proactive communication.
                </Text>
                <Text>
                    If you're ready to experience the finest in property management services, contact West Hollywood Property Management. Our consultants are standing by to offer a customized management plan that aligns with your goals as a property owner in West Hollywood. Embrace the ease of professional property management and watch as your investment thrives under our expert care.
                </Text>
                <Text>
                    Reach out today to learn more about how we can transform your property management experience and elevate your West Hollywood property to new heights.
                </Text>
            </Stack>
        </Box>
    )
}