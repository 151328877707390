import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const EmergencyResponse = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Reliable 24/7 Emergency Response Services</Heading>
        <Text>
          At West Hollywood Property Management, we understand that emergencies
          wait for no one and timing is everything. This is why we provide
          comprehensive 24/7 emergency response services, ensuring that when the
          unexpected occurs, immediate action is taken to protect your property
          and tenants.
        </Text>
        <Text>
          Our emergency response team is thoroughly trained to handle a wide
          range of urgent scenarios with speed, efficiency, and expertise. From
          burst pipes in the dead of night to power outages during a holiday,
          our responsive approach is designed to address and resolve issues
          swiftly, minimizing damage and disruption.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Monthly Financial Statements:</b> Receive itemized monthly
            statements that detail income and expenditures. Our
            easy-to-understand reports are designed to keep you well-informed
            about your property's financial activity. These statements provide a
            clear breakdown of rental income, expenses, and other financial
            transactions, allowing you to track your property's performance with
            transparency and accuracy.
          </ListItem>
          <ListItem>
            <b>Comprehensive Tax Guidance:</b> Our year-end financial summaries
            simplify tax preparation. With a clear overview of the year's
            finances, we make tax filing a less daunting task for you or your
            accountant. Our comprehensive tax guidance includes organizing all
            relevant financial documents, providing detailed expense reports,
            and offering strategic advice to optimize your tax position.
          </ListItem>
          <ListItem>
            <b>Expenditure Tracking:</b> We track every penny with a focus on
            transparency. Our expenditure reports give you a full account of
            maintenance costs, capital improvements, and operational expenses,
            ensuring you're never in the dark about your investments. By
            maintaining meticulous records, we help you understand where your
            money is going and make informed decisions to optimize your
            property's financial performance.
          </ListItem>
          <ListItem>
            <b>Revenue Optimization Strategies:</b> With a pulse on West
            Hollywood's property market, we provide actionable insights aimed at
            enhancing your property’s profitability. We identify trends and
            opportunities to help you maximize your investment's earning
            potential. Our revenue optimization strategies include adjusting
            rental rates based on market conditions, recommending cost-effective
            property improvements, and implementing marketing initiatives to
            attract high-quality tenants.
          </ListItem>
          <ListItem>
            <b>Customized Financial Consultations:</b> We understand that every
            property owner has unique financial goals. That's why we offer
            customized financial consultations to discuss your specific needs,
            answer any questions you may have, and provide tailored advice to
            help you achieve your investment objectives.
          </ListItem>
          <ListItem>
            <b>Real-Time Financial Portal Access:</b> Stay connected to your
            property's financial performance with our real-time financial
            portal. Access key financial metrics, reports, and statements at
            your convenience, empowering you with the information you need to
            make informed decisions about your investment.
          </ListItem>
        </UnorderedList>

        <Text>
          Partner with West Hollywood Property Management for unparalleled
          emergency support. We take on the responsibility of shielding your
          investment from the unpredictability of emergencies, allowing you to
          rest easy with the knowledge that we’ve got everything under control,
          always.
        </Text>
      </Stack>
    </Box>
  );
};
