import React from "react";
import {Box, Heading, ListItem, Stack, UnorderedList, Text} from "@chakra-ui/react";

export const MaintenanceServices = ()=>{
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h1'} fontSize="xl">Premier Maintenance Services for West Hollywood Properties</Heading>
                <Text>
                    At West Hollywood Property Management, we provide an unrivaled maintenance service that stands as a testament to our commitment to property care. Understanding that a well-maintained property is critical to preserving and increasing its value, we spare no effort in ensuring that your investment is meticulously cared for.
                </Text>
                <Text>
                    Our dedicated maintenance team, comprised of seasoned professionals, is available 24/7 to respond to both routine and unexpected property needs. We combine expertise, efficiency, and a keen attention to detail to keep your property in pristine condition.
                </Text>
                <Heading as={'h2'} fontSize="lg">Our Comprehensive Maintenance Solutions</Heading>
                <Text>
                    We recognize that maintenance extends beyond fixing issues—it’s about preventative care and enhancing the property's appeal. Here's an outline of the maintenance services we proudly offer:
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Thorough Regular Inspections:</b> We proactively schedule regular inspections to ensure early detection of potential issues, averting costly repairs and prolonging the life of your property.
                    </ListItem>
                    <ListItem>
                        <b>Responsive Emergency Repairs:</b> Our responsive maintenance team is equipped to handle emergency repairs at a moment's notice, ensuring that both property and tenant are safeguarded from unforeseen issues.
                    </ListItem>
                    <ListItem>
                        <b>Assured Quality Work:</b> Our work isn’t just quick; it meets the highest standards of quality. With a keen eye for detail, we ensure that all maintenance tasks, from the simplest to the most complex, are completed flawlessly.
                    </ListItem>
                    <ListItem>
                        <b>Seamless Vendor Management:</b> We oversee all interactions with third-party vendors, from negotiation to job completion, ensuring work is done efficiently and in alignment with our quality expectations.
                    </ListItem>
                </UnorderedList>
                <Text>
                    We extend our maintenance services to encompass landscaping, renovations, and modernizations, enhancing curb appeal and property functionality. By choosing West Hollywood Property Management, you are opting for a partner who invests in your property’s longevity and desirability.
                </Text>
                <Text>
                    Our maintenance approach is tailored to the unique requirements of your property. Whether it's a historical building requiring specialized care, a contemporary complex demanding the latest in property technology, or anything in between, our team is equipped and ready.
                </Text>
                <Text>
                    Trust in West Hollywood Property Management for all-encompassing maintenance services that protect, improve, and elevate your property investment. We invite you to experience the peace of mind that comes with knowing your property is in expert hands.
                </Text>
            </Stack>
        </Box>
    )
}