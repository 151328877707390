import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const RentCollection = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Optimized Rent Collection Services in West Hollywood
        </Heading>
        <Text>
          Efficient rent collection is the backbone of successful property
          management, and at West Hollywood Property Managers, we ensure that
          it's a smooth, hassle-free experience for both property owners and
          tenants. We utilize the latest in property management technology to
          streamline the entire rent collection process.
        </Text>
        <Text>
          With our advanced online portal, we provide tenants with a
          user-friendly platform for submitting their payments quickly and
          securely. This modern approach minimizes delays and enhances the
          financial flow, ensuring that you, the property owner, receive your
          funds promptly.
        </Text>
        <Heading as={"h3"} fontSize="2xl">
          Enhancing Rent Collection with Technology and Expertise
        </Heading>
        <Text>
          We have refined our rent collection services to include several key
          features, each designed to encourage on-time payments and provide you
          with a comprehensive understanding of your property's financial
          status:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Convenient Digital Payments:</b> Our online payment system is
            accessible 24/7, enabling tenants to pay rent from anywhere, at any
            time, without the hassle of paper checks. This convenience enhances
            tenant satisfaction and reduces the likelihood of late payments.
          </ListItem>
          <ListItem>
            <b>Proactive Payment Reminders:</b> We send automated reminders to
            tenants, reducing the chance of late payments and ensuring a steady
            income stream for you. Our reminders are tailored to each tenant's
            preferred communication method, maximizing effectiveness.
          </ListItem>
          <ListItem>
            <b>In-Depth Financial Reporting:</b> You’ll receive detailed,
            transparent monthly statements that track your rental income and
            expenses, providing insights into your investment's performance. Our
            comprehensive reports help you make informed decisions about your
            property investment strategy.
          </ListItem>
          <ListItem>
            <b>Effective Default Management:</b> On the rare occasion that a
            tenant fails to pay, our experienced team handles the situation
            promptly, with a focus on legal compliance and maintaining positive
            landlord-tenant relations. We prioritize clear communication and
            mediation to resolve payment disputes amicably.
          </ListItem>
          <ListItem>
            <b>Flexible Payment Options:</b> In addition to online payments, we
            offer multiple payment channels to accommodate diverse tenant
            preferences. From bank transfers to credit card payments, we ensure
            that tenants have convenient options for fulfilling their rental
            obligations.
          </ListItem>
          <ListItem>
            <b>Customized Rent Collection Strategies:</b> We understand that
            every property is unique, which is why we tailor our rent collection
            strategies to align with your specific goals and preferences.
            Whether you prefer a hands-on approach or prefer to delegate rent
            collection entirely, we adapt to suit your needs.
          </ListItem>
        </UnorderedList>
        <Text>
          At West Hollywood Property Managers, we believe that a rigorous yet
          fair rent collection process is essential for maintaining the
          profitability of your rental property. Our methods are designed not
          only to preserve the financial viability of your property but also to
          accommodate the needs of your tenants, creating a respectful and
          professional relationship.
        </Text>
        <Text>
          Whether you own a single-family home, a multi-unit apartment complex,
          or a large portfolio of properties, our rent collection services are
          scalable and adaptable to your specific needs. Let us take the burden
          of rent collection off your shoulders, allowing you to enjoy the
          rewards of your investment without the associated administrative
          stress.
        </Text>
        <Text>
          Contact West Hollywood Property Managers today to learn how we can
          transform your rent collection process and elevate your property
          management experience to the next level.
        </Text>
      </Stack>
    </Box>
  );
};
