import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const TenantRelations = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h1'} fontSize="xl">Exemplary Tenant Relations in West Hollywood</Heading>
                <Text>
                    At West Hollywood Property Management, we understand that the cornerstone of a thriving rental
                    property is the relationship between tenants and landlords. We are committed to nurturing this
                    dynamic to ensure a stable, respectful, and mutually beneficial partnership.
                </Text>
                <Text>
                    Effective tenant relations are not just about conflict resolution — they are about creating an
                    environment where issues seldom arise. We employ a proactive approach, addressing potential concerns
                    before they escalate, thus fostering a sense of community and respect.
                </Text>
                <Heading as={'h2'} fontSize="lg">Our Approach to Tenant Relations</Heading>
                <Text>
                    Our philosophy centers on the belief that happy tenants equal a successful, profitable property. We
                    prioritize transparent communication, swift action on issues, and a personalized touch that makes
                    tenants feel valued and heard. Here’s how we make this happen:
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Proactive Conflict Resolution:</b> When disputes occur, we step in swiftly with impartiality
                        and professionalism. By mediating issues effectively, we prevent escalation and ensure that all
                        parties are satisfied with the outcome, maintaining a peaceful living environment.
                    </ListItem>
                    <ListItem>
                        <b>Clear and Open Communication:</b> Keeping everyone informed is key to good relations. Whether
                        it’s maintenance updates, policy changes, or just a check-in, our open lines of communication
                        ensure tenants and landlords are always on the same page.
                    </ListItem>
                    <ListItem>
                        <b>Strategies for Tenant Retention:</b> We believe in building long-term relationships. Through
                        our exceptional service and attention to tenant satisfaction, we retain tenants longer, which
                        contributes to the stable financial performance of your property.
                    </ListItem>
                    <ListItem>
                        <b>Regular Updates and Reports:</b> Landlords are kept in the loop with regular updates. Whether
                        it’s the status of units, financial reports, or tenant feedback, we provide all the information
                        you need to stay informed about your property’s well-being.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Our tenant relations services extend beyond these pillars. We conduct regular tenant satisfaction
                    surveys to gauge the mood and needs of the residents, organize community-building events to
                    strengthen the tenant community, and implement efficiency in all tenant-facing processes, from
                    onboarding to moving out.
                </Text>
                <Text>
                    We understand that a tenant's positive experience is reflective of the property management’s
                    quality. With West Hollywood Property Management, you can trust that your tenants are in capable
                    hands—hands that prioritize their comfort and your peace of mind.
                </Text>
                <Text>
                    Partner with us at West Hollywood Property Management for tenant relations that translate into
                    enduring value for your property investment. Our dedicated team is ready to demonstrate the
                    difference that professional property management can make in the lives of both landlords and
                    tenants.
                </Text>
            </Stack>
        </Box>
    )
}