import React from "react";
import {Box, Heading, ListItem, Stack, UnorderedList, Text} from "@chakra-ui/react";

export const TenantScreening = ()=>{
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Comprehensive Tenant Screening Services</Heading>
                <Text>
                    Discovering a trustworthy tenant is foundational to the prosperity of your real estate venture. At West Hollywood Property Managers, we understand the paramount importance of selecting the right tenants to uphold the integrity and profitability of your investment properties. Our meticulous tenant screening process stands as a cornerstone of our commitment to excellence, designed to deliver unparalleled peace of mind and foster a stable, rewarding rental experience for property owners across West Hollywood and its neighboring communities.
                    <br />
                    When it comes to tenant screening, we go above and beyond, leveraging a comprehensive blend of state-of-the-art technology and personalized, hands-on approaches. Our team of experienced professionals meticulously evaluates each applicant, delving into their rental history, creditworthiness, employment status, and criminal background to ensure that only the most qualified individuals are selected to reside in your properties.
                </Text>
                <Text>
                    We understand that the quality of tenants directly impacts the long-term success and profitability of your real estate investments. That's why we employ a multi-faceted screening process that leaves no stone unturned. By utilizing advanced screening tools and conducting thorough interviews, we strive to gain deep insights into each applicant's character, reliability, and commitment to maintaining a harmonious living environment.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Detailed Background Checks:</b> Our detailed background checks delve deep into every aspect of the applicant's history, encompassing prior rental experiences, eviction records, criminal background checks, and any other relevant public records. By conducting thorough investigations, we ensure that prospective tenants meet the highest standards of reliability and integrity, providing you with peace of mind in your tenant selection process.
                    </ListItem>
                    <ListItem>
                        <b>Credit Evaluations:</b> Our credit evaluations involve a meticulous review of the applicant's credit scores, payment history, outstanding debts, and overall financial standing. By analyzing this information, we assess the financial responsibility and capability of potential tenants, enabling you to make informed decisions and mitigate financial risks associated with renting your property.
                    </ListItem>
                    <ListItem>
                        <b>Rigorous Employment Verification:</b> We understand the importance of stable income in ensuring consistent rent payments and upholding the terms of the lease agreement. Our team conducts rigorous employment verifications, verifying job tenure, income stability, and employment status to provide you with confidence in your tenant's ability to fulfill their financial obligations.
                    </ListItem>
                    <ListItem>
                        <b>Thorough Reference Checks:</b> Our reference checks involve contacting previous landlords, employers, and other relevant references provided by the applicant. Through these interactions, we gather valuable insights into the applicant's rental history, character, and reliability. By verifying the information provided and assessing the applicant's behavior and credibility, we add an extra layer of confidence to the tenant screening process, ensuring that you select tenants who align with your property's standards and values.
                    </ListItem>
                    <ListItem>
                        <b>Income Verification:</b> In addition to employment verification, we also verify the applicant's income through pay stubs, tax returns, and other financial documents. This thorough process helps ensure that potential tenants have the financial means to consistently meet their rental obligations and contribute positively to the stability of your property.
                    </ListItem>
                    <ListItem>
                        <b>Residential History Analysis:</b> Understanding the applicant's past residential history is essential in predicting their future behavior as a tenant. We meticulously analyze previous addresses, rental durations, and reasons for moving to gain insights into their stability and reliability as a long-term tenant.
                    </ListItem>
                </UnorderedList>
                <Text>
  Our tenant screening process isn't merely about filtering potential issues; it's about sculpting a vibrant community, ensuring that those who move into your property not only care for it but also contribute positively to the neighborhood's fabric. At West Hollywood Property Managers, we recognize the importance of fostering a sense of belonging and camaraderie among tenants, creating a harmonious living environment where everyone thrives.
  <br />
  By entrusting your tenant screening to West Hollywood Property Managers, you're not just filling vacancies; you're cultivating a culture of respect, responsibility, and mutual trust. Our comprehensive screening procedures go beyond surface-level assessments to identify individuals who share your values and respect your property. We believe that every tenant plays a vital role in shaping the character of your rental community, and our goal is to ensure that each new resident contributes positively to its growth and prosperity.
</Text>

<Text>
  Connect with us today to learn more about how our meticulous tenant screening process can elevate the standard of your rental property. Whether you're a seasoned property owner or a first-time landlord, we're here to provide the expertise and support you need to make informed decisions and protect your investment for years to come. Let us help you create a living environment where tenants feel valued, respected, and proud to call home.
</Text>

            </Stack>
        </Box>
    )
}